import { useState, useEffect } from "react";
import { HeaderButton } from "../components/HeaderButton";
import YoutubeIcon from "../icons/YoutubeIcon";

export const Header = () => {
  const [visible, setVisible] = useState(true);
  const [yOffset, setYOffset] = useState(window.scrollY);
  const [showHeaderButtons, setShowHeaderButtons] = useState(true);

  const handleSizeChange = () => {
    setShowHeaderButtons(window.innerWidth > 700);
  };

  useEffect(() => {
    window.addEventListener("resize", handleSizeChange);
    return () => window.removeEventListener("resize", handleSizeChange);
  }, []);

  const handleScroll = () => {
    setVisible(window.scrollY < yOffset);
    setYOffset(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [yOffset]);

  return (
    <div
      className={`fixed w-full top-0 z-[20] transition-all duration-300 backdrop-blur-md bg-black/70 border-b border-gray-800/50
        ${visible ? 'translate-y-0' : '-translate-y-full'}`}
    >
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center h-20 justify-between">
          {/* Logo/Brand */}
          <h1 className="text-3xl md:text-4xl font-headings bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent hover:from-gray-100 hover:to-gray-500 transition-all duration-300">
            Panemone Productions
          </h1>

          {/* Navigation */}
          {showHeaderButtons && (
            <nav className="hidden md:flex space-x-1">
              {['Portfolio', 'Services', 'Inquiries'].map((text) => (
                <HeaderButton 
                  key={text} 
                  text={text} 
                  className="px-6 py-2 rounded-lg transition-all duration-300 hover:bg-gray-800/50" 
                />
              ))}
            </nav>
          )}

          {/* Social Links */}
          <div className="flex items-center space-x-4">
            <a
              href="https://www.youtube.com/channel/UCIWC4GDrIj8FjdDAVO0q9pA"
              target="_blank"
              rel="noopener noreferrer"
              className="transform hover:scale-110 transition-all duration-300"
            >
              <YoutubeIcon className="w-8 h-8" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
