import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const numOfPictures = 8;

export const Carousel = () => {
  const imageCarousel = Array(numOfPictures)
    .fill(1)
    .map((_, i) => `p-${i}.jpg`);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <div className="w-full lg:w-[60%] px-4 lg:py-10">
      <Slider {...settings}>
        {imageCarousel.map((image, index) => (
          <div key={index} className="px-1">
            <div className="aspect-[3/8] relative overflow-hidden rounded-xl">
              <img 
                src={image} 
                alt={`Slide ${index + 1}`}
                className="object-cover w-full h-full transform hover:scale-105 transition-transform duration-300"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
