import * as React from "react";
const PhoneIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <title>{"phone"}</title>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.635 8.737c-1.7 3.23-5.475 8.202-8.635 11.481-3.643 3.94-12.231 9.328-12.231 9.328-.663.348-2.503.374-3.009-.131l-1.831-1.826c-.378-.632-.548-1.364 0-1.826l3.663-2.74c.62-.419 1.325-.504 1.831 0l2.257 2.25c.531-.373 9.009-5.929 13.725-13.578L23.094 9.39c-.506-.504-.42-1.208 0-1.827l2.747-3.652c.506-.675 1.326-.505 1.831 0l1.832 1.826c.718.674.51 2.245.131 3Zm1.438-4.304-2.878-2.87a2.038 2.038 0 0 0-2.878 0L21 7.303c-.659.971-.794 2.077 0 2.87l1.762 1.757c-1.306 1.75-2.874 3.658-4.639 5.418-1.996 1.99-4.216 3.799-6.212 5.285l-1.703-1.697c-.794-.793-1.903-.659-2.878 0l-5.756 4.305c-.965.665-.795 2.077 0 2.87l2.878 2.869c1.59 1.586 3.657 1.078 5.756 0 0 0 6.353-3.556 11.792-8.98 5.116-5.102 9.073-11.827 9.073-11.827.825-2.263 1.59-4.155 0-5.74Z"
    />
  </svg>
);
export default PhoneIcon;
