export const ServiceItem = ({ identifier }) => {
  return (
    <div className="flex flex-col justify-content lg:w-[31%] lg:min-w-[350px] mb-3 space-y-2 bg-gray-800/50 p-6 shadow-2xl rounded-xl backdrop-blur-sm border border-gray-700 hover:border-gray-500 transition-all duration-300">
      <img
        src={identifier.imgUrl}
        className="rounded-xl object-cover aspect-video"
        alt={identifier.title}
      />
      <h3 className="font-headings text-4xl p-2 self-center text-gray-100">
        {identifier.title}
      </h3>
      <p className="font-text p-4 text-xl text-gray-300">{identifier.description}</p>
    </div>
  );
};
