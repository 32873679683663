import { Title } from "../components/Title";
import { contact } from "../content/contact";
const InquirySection = ({ type }) => {
  const { heading, content, Icon, iconWidth } = contact[type];
  return (
    <div className="flex w-full flex-col justify-evenly w-[50%] items-center">
      <h1 className="text-4xl p-2 text-center">{heading}</h1>
      <p>{content}</p>
      <Icon width={iconWidth} />
    </div>
  );
};

export const Inquiries = () => {
  return (
    <div id="inquiries" className="py-8">
      <Title name={"Inquiries"} />
      <div className="md:flex gap-6 p-4">
        <div className="flex-1 backdrop-blur-sm bg-gray-800/30 rounded-xl p-8 border border-gray-700">
          <InquirySection type="email" />
        </div>
        <div className="flex-1 backdrop-blur-sm bg-gray-800/50 rounded-xl p-8 border border-gray-700 mt-6 md:mt-0">
          <InquirySection type="phone" />
        </div>
      </div>
    </div>
  );
};
