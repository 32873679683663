import PhoneIcon from "../icons/Phone";
import EmailIcon from "../icons/Email";
export const contact = {
  email: {
    heading: "Email us",
    content: "callumobrien@panemoneproductions.com",
    Icon: EmailIcon,
    iconWidth: 50,
  },
  phone: {
    heading: "Call us",
    content: "+44 7873 868 711",
    Icon: PhoneIcon,
    iconWidth: 40,
  },
};
