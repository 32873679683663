import { Header, ImageBoard, Portfolio, Services, Inquiries } from "./views";
import { Section } from "./components/Section";

function App() {
  return (
    <div className="relative min-h-screen text-gray-100">
      {/* Animated background */}
      <div className="fixed inset-0 -z-10 bg-gray-900 overflow-hidden">
        {/* Glowing orbs */}
        <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-blue-500/20 rounded-full blur-[128px] animate-orb1" />
        <div className="absolute top-1/3 right-1/3 w-96 h-96 bg-purple-500/20 rounded-full blur-[128px] animate-orb2" />
        <div className="absolute bottom-1/4 left-1/3 w-96 h-96 bg-pink-500/20 rounded-full blur-[128px] animate-orb3" />
        
        {/* Overlay to darken and add texture */}
        <div className="absolute inset-0 bg-gray-900/70 backdrop-blur-[1px]" />
      </div>

      <Header />
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20">
        <ImageBoard />
        <div className="space-y-12">
          <Section 
            SectionItem={Portfolio} 
            className="transform hover:scale-105 duration-300"
          />
          <Section 
            SectionItem={Services}
            className="backdrop-blur-sm bg-gray-800/30 rounded-3xl p-8"
          />
          <Section 
            SectionItem={Inquiries}
            className="border border-gray-700 rounded-3xl bg-gray-800/50 p-8"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
